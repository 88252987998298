import { Link } from 'react-router-dom'
import React from 'react'

// Components
import GlobalStyles from '../../../components/component-items/styles'
import { TableCell } from '../../../components/component-items/datatable'

export const TransactionListFiltered = ({ transactions }: any) => (
  <div style={{ minHeight: 500, width: '100%' }}>
    <GlobalStyles.DataTable>
      <thead>
        <tr>
          <th>
            <span className="text">Date</span>
          </th>
          <th>
            <span className="text">Order</span>
          </th>
          <th>
            <span className="text">Transaction</span>
          </th>
          <th>
            <span className="text">Amount</span>
          </th>
        </tr>
      </thead>
      <tbody>
        {transactions.map((t: any, id: number) => (
          <GlobalStyles.TableRow key={id}>
            <TableCell>{t.created}</TableCell>
            <TableCell
              center
              input={
                <Link to={`/order/${t.order}/`} rel="noreferrer" target="_blank">
                  {t.order}
                </Link>
              }
            />
            <TableCell>{t.description}</TableCell>
            <TableCell input={'$' + t.amount} />
          </GlobalStyles.TableRow>
        ))}
      </tbody>
    </GlobalStyles.DataTable>
  </div>
)
