import React from 'react'
import { globalState } from '../../../../../store'
import { fetchResults } from './index'

export const OrderConditionOptions = () => (
  <>
    <option value={'all_orders'}>All Orders</option>
    <optgroup label="Order Options">
      <option value={'order_field'}>Order Field</option>
      <option value={'address_field'}>Address Field</option>
    </optgroup>
    <optgroup label="Logical Type">
      <option value={'logical_and'}>AND</option>
      <option value={'logical_or'}>OR</option>
      <option value={'logical_not'}>NOT</option>
    </optgroup>
    <optgroup label="Order Item Options">
      <option value={'order_item_any'}>Match ANY Order Items</option>
      <option value={'order_item_all'}>Match ALL Order Items</option>
      <option value={'order_item_none'}>Match No Order Items</option>
    </optgroup>
  </>
)

export const OrderEventOptions = () => (
  <>
    <option value={'now'}>Now</option>
    <option value={'order_created'}>Order Created</option>
    <option value={'order_status'}>Order Status Changes</option>
  </>
)

// TODO - make actionable an array of user roles vs boolean after user access scopes are implemented
export const OrderStatusTypes = [
  { value: 10, label: 'Cancelled', actionable: true, color: 'red' },
  { value: 70, label: 'Paused', actionable: true, color: 'yellow' },
  { value: 80, label: 'Backordered', actionable: true, color: 'yellow' },
  { value: 90, label: 'Received', actionable: true, color: 'blue' },
  { value: 100, label: 'Processing', actionable: false, color: 'blue' },
  { value: 110, label: 'Picked', actionable: false, color: 'blue' },
  { value: 120, label: 'Packed', actionable: false, color: 'blue' },
  { value: 145, label: 'Label Purchase Fail', actionable: false, color: 'red' },
  { value: 150, label: 'Rate Limit Exceeded', actionable: false, color: 'red' },
  { value: 160, label: 'Label Purchased', actionable: false, color: 'blue' },
  { value: 200, label: 'Shipped', actionable: false, color: 'blue' },
  { value: 300, label: 'Delivered', actionable: false, color: 'green' },
]

export const RestrictedOrderStatusTypes = OrderStatusTypes.filter((option: any) => option.number >= 100)

export const OrderFieldTypes = () => {
  const {
    state: { activeWarehouses: activeWarehousesWithID, companyId },
  } = globalState()

  const shippingMethods = async (inputValue: string) => {
    const productURL = `/api/company/${companyId}/shipping-method/?q=${encodeURIComponent(inputValue)}`
    let result = await fetchResults(productURL)
    result = result['methods'] ? result.methods : result
    return result.map((option: any) => ({
      label: option.remote_method,
      value: option.id,
    }))
  }

  const activeWarehouses = activeWarehousesWithID.map((option: any) => ({ label: option.name, value: option.id }))
  return [
    { value: 'id', label: 'Order ID', type: 'number', options: [], async: false, actionable: false },
    { value: 'status', label: 'Status', type: 'number', options: OrderStatusTypes, async: false, actionable: false },
    {
      value: 'warehouse',
      label: 'Warehouse',
      type: 'number',
      options: activeWarehouses,
      async: false,
      actionable: true,
    },
    {
      value: 'shipping_method',
      label: 'Shipping Method',
      type: 'number',
      options: shippingMethods,
      async: true,
      actionable: true,
    },
    { value: 'created', label: 'Created', type: 'date', options: [], async: false, actionable: false },
    { value: 'updated', label: 'Updated', type: 'date', options: [], async: false, actionable: false },
    { value: 'b_to_b', label: 'B2B', type: 'boolean', options: [], async: false, actionable: true },
    { value: 'edi', label: 'EDI', type: 'boolean', options: [], async: false, actionable: false },
    { value: 'hot', label: 'Hot', type: 'boolean', options: [], async: false, actionable: true },
    { value: 'volume', label: 'Volume (cu ft)', type: 'number', options: [], async: false, actionable: false },
    { value: 'weight', label: 'Weight (oz)', type: 'number', options: [], async: false, actionable: false },
    { value: 'originator_id', label: 'originator_id', type: 'number', options: [], async: false, actionable: false },
    {
      value: 'originator_number',
      label: 'originator_number',
      type: 'number',
      options: [],
      async: false,
      actionable: false,
    },
    {
      value: 'shop_shipping_method_text',
      label: 'Shipping Method Text',
      type: 'string',
      options: [],
      async: false,
      actionable: true,
    },
    {
      value: 'saturday_delivery',
      label: 'Saturday Delivery',
      type: 'boolean',
      options: [],
      async: false,
      actionable: true,
    },
    { value: 'tags', label: 'Tags (comma separated)', type: 'string', options: [], async: false, actionable: true },
    {
      value: 'sortable_key',
      label: 'Order Items Sortable Key',
      type: 'string',
      options: [],
      async: false,
      actionable: false,
    },
    { value: 'ship_before', label: 'Ship Before Date', type: 'date', options: [], async: false, actionable: true },
    { value: 'ship_after', label: 'Ship After Date', type: 'date', options: [], async: false, actionable: true },
    {
      value: 'order_recurring',
      label: 'Recurring Orders',
      type: 'boolean',
      options: [],
      async: false,
      actionable: true,
    },
    {
      value: 'customers_first_order',
      label: 'First Customer Order',
      type: 'boolean',
      options: [],
      async: false,
      actionable: true,
    },
    {
      value: 'locally_modified',
      label: 'Locally Modified',
      type: 'boolean',
      options: [],
      async: false,
      actionable: false,
    },
    { value: 'freight', label: 'Freight', type: 'boolean', options: [], async: false, actionable: true },
    {
      value: 'is_replacement',
      label: 'Replacement Order',
      type: 'boolean',
      options: [],
      async: false,
      actionable: false,
    },
    {
      value: 'contains_alcohol',
      label: 'Contains Alcohol',
      type: 'boolean',
      options: [],
      async: false,
      actionable: false,
    },
    { value: 'note', label: 'Note', type: 'string', options: [], async: false, actionable: true },
    { value: 'total_price', label: 'Total Price', type: 'number', options: [], async: false, actionable: false },
    {
      value: 'use_wholesale_prices',
      label: 'Use Wholesale Prices',
      type: 'boolean',
      options: [],
      async: false,
      actionable: true,
    },
    {
      value: 'override_shipping_limit',
      label: 'Override Shipping Limit',
      type: 'boolean',
      options: [],
      async: false,
      actionable: true,
    },
    { value: 'insure', label: 'Insure', type: 'boolean', options: [], async: false, actionable: true },
    { value: 'insurance_value', label: 'Insurance Value', type: 'number', options: [], async: false, actionable: true },
    {
      value: 'require_signature',
      label: 'Require Signature',
      type: 'boolean',
      options: [],
      async: false,
      actionable: true,
    },
    {
      value: 'require_adult_signature',
      label: 'Require Adult Signature',
      type: 'boolean',
      options: [],
      async: false,
      actionable: true,
    },
  ]
}

export const OrderActionOptions = ({ event }: any) => {
  const isRestricted =
    event.category === 'order_status' &&
    RestrictedOrderStatusTypes.filter((s) => s.value === event.conditions).length > 0
  return (
    <>
      {!isRestricted && (
        <>
          <optgroup label="Order Action">
            <option value={'edit_order_field'}>Edit Order</option>
            <option value={'edit_order_item'}>Edit Order Item</option>
            <option value={'pause_order'}>Pause Order</option>
            <option value={'unpause_order'}>Unpause Order</option>
            <option value={'cancel_order'}>Cancel Order</option>
            <option value={'choose_best_warehouse'}>Choose Best Warehouse</option>
          </optgroup>
          <optgroup label="Address Action">
            <option value={'edit_address_field'}>Edit Address</option>
          </optgroup>
        </>
      )}
      <optgroup label="Notification">
        <option value={'send_notification'}>Send Notification</option>
      </optgroup>
      <optgroup label="Logical Type">
        <option value={'logical_and'}>AND</option>
      </optgroup>
    </>
  )
}

export const orderItemFieldTypes = () => [
  { value: 'product_variant__sku', label: 'SKU', type: 'string', options: [], async: false, actionable: false },
  { value: 'product_variant__barcode', label: 'Barcode', type: 'string', options: [], async: false, actionable: false },
  { value: 'quantity', label: 'Quantity', type: 'number', options: [], async: false, actionable: false },
  {
    value: 'product_variant__product__promo',
    label: 'Promo (true/false)',
    type: 'boolean',
    options: [],
    async: false,
    actionable: false,
  },
  {
    value: 'product_variant__is_bundle',
    label: 'Bundle (true/false)',
    type: 'boolean',
    options: [],
    async: false,
    actionable: false,
  },
  {
    value: 'product_variant__weight',
    label: 'Variant Weight',
    type: 'number',
    options: [],
    async: false,
    actionable: false,
  },
  {
    value: 'product_variant__length',
    label: 'Variant Length',
    type: 'number',
    options: [],
    async: false,
    actionable: false,
  },
  {
    value: 'product_variant__width',
    label: 'Variant Width',
    type: 'number',
    options: [],
    async: false,
    actionable: false,
  },
  {
    value: 'product_variant__height',
    label: 'Variant Height',
    type: 'number',
    options: [],
    async: false,
    actionable: false,
  },
]

export const AddressFieldType = () => (
  <>
    <option value={''} disabled hidden>
      - Field Type -
    </option>
    <option value={'name'}>Name</option>
    <option value={'email'}>Email</option>
    <option value={'line1'}>Line1</option>
    <option value={'line2'}>Line2</option>
    <option value={'city'}>City</option>
    <option value={'state'}>State</option>
    <option value={'zip'}>Zip</option>
    <option value={'country'}>Country</option>
    <option value={'phone'}>Phone</option>
    <option value={'business'}>Business</option>
  </>
)

export const AddressFieldTypes = () => [
  { value: 'name', label: 'Name', type: 'string', options: [], async: false, actionable: true },
  { value: 'email', label: 'Email', type: 'string', options: [], async: false, actionable: true },
  { value: 'line1', label: 'Line1', type: 'string', options: [], async: false, actionable: true },
  { value: 'line2', label: 'Line2', type: 'string', options: [], async: false, actionable: true },
  { value: 'city', label: 'City', type: 'string', options: [], async: false, actionable: true },
  { value: 'state', label: 'State', type: 'string', options: [], async: false, actionable: true },
  { value: 'zip', label: 'Zip', type: 'string', options: [], async: false, actionable: true },
  { value: 'country', label: 'Country', type: 'string', options: [], async: false, actionable: true },
  { value: 'phone', label: 'Phone', type: 'string', options: [], async: false, actionable: true },
  { value: 'business', label: 'Business', type: 'string', options: [], async: false, actionable: true },
]

// export const OrderOperators = () => (
//   <>
//     <option value={''} disabled hidden>
//       - Field Operator -
//     </option>
//     <option value={'exact'}>is</option>
//     <option value={'exclude_exact'}>is not</option>
//     <option value={'istartswith'}>starts with</option>
//     <option value={'iendswith'}>ends with</option>
//     <option value={'lt'}>less than</option>
//     <option value={'lte'}>less than or equal</option>
//     <option value={'gt'}>greater than</option>
//     <option value={'gte'}>greater than or equal</option>
//     <option value={'icontains'}>contains</option>
//     <option value={'iregex'}>matches (regular exp)</option>
//     <option value={'isnull'}>is blank</option>
//     <option value={'exclude_isnull'}>is not blank</option>
//     <option value={'in'}>in (list)</option>
//   </>
// )
