import React from 'react'
import { globalState } from '../../../../../store'

export const ReturnConditionOptions = () => (
  <>
    <option value={'all_returns'}>All Returns</option>
    <optgroup label="Return Options">
      <option value={'return_field'}>Return Field</option>
    </optgroup>
    <optgroup label="Logical Type">
      <option value={'logical_and'}>AND</option>
      <option value={'logical_or'}>OR</option>
      <option value={'logical_not'}>NOT</option>
    </optgroup>
    <optgroup label="Return Item Options">
      <option value={'return_item_any'}>Match ANY Return Items</option>
      <option value={'return_item_all'}>Match ALL Return Items</option>
      <option value={'return_item_none'}>Match No Return Items</option>
    </optgroup>
  </>
)

export const ReturnEventOptions = () => (
  <>
    <option value={'now'}>Now</option>
    <option value={'return_created'}>Return Created</option>
    <option value={'return_status'}>Return Status Changes</option>
  </>
)

export const ReturnActionOptions = ({ event }: any) => {
  const isRestricted =
    event.category === 'return_status' &&
    RestrictedReturnStatusTypes.filter((s) => s.value === event.conditions).length > 0
  return (
    <>
      {!isRestricted && (
        <>
          <optgroup label="Return Action">
            <option value={'edit_return_field'}>Edit Return</option>
            <option value={'edit_return_item'}>Edit Return Item</option>
          </optgroup>
        </>
      )}
      <optgroup label="Notification">
        <option value={'send_notification'}>Send Notification</option>
      </optgroup>
      <optgroup label="Logical Type">
        <option value={'logical_and'}>AND</option>
      </optgroup>
    </>
  )
}

export const ReturnStatusTypes = [
  { value: 50, label: 'Return New', actionable: true, color: 'red' },
  { value: 60, label: 'Return Sent to Customer', actionable: true, color: 'red' },
  { value: 70, label: 'Return Label Purchase Fail', actionable: true, color: 'red' },
]

export const RestrictedReturnStatusTypes = ReturnStatusTypes.filter((option: any) => option.number >= 60)

export const ReturnFieldTypes = () => {
  const {
    state: { activeWarehouses: activeWarehousesWithID },
  } = globalState()

  const activeWarehouses = activeWarehousesWithID.map((option: any) => ({ label: option.name, value: option.id }))
  return [
    { value: 'id', label: 'Return ID', type: 'number', options: [], async: false, actionable: false },
    { value: 'status', label: 'Status', type: 'number', options: ReturnStatusTypes, async: false, actionable: false },
    {
      value: 'warehouse',
      label: 'Warehouse',
      type: 'number',
      options: activeWarehouses,
      async: false,
      actionable: true,
    },
    { value: 'created', label: 'Created', type: 'date', options: [], async: false, actionable: false },
    { value: 'updated', label: 'Updated', type: 'date', options: [], async: false, actionable: false },
    { value: 'sender', label: 'Sender', type: 'string', options: [], async: false, actionable: true },
    { value: 'delivery_date', label: 'Delivery Date', type: 'date', options: [], async: false, actionable: true },
    { value: 'is_overdue', label: 'Is Overdue', type: 'boolean', options: [], async: false, actionable: false },
    { value: 'date_received', label: 'Date Received', type: 'date', options: [], async: false, actionable: false },
    { value: 'tracking_number', label: 'Tracking Number', type: 'string', options: [], async: false, actionable: true },
    {
      value: 'integration_id',
      label: 'Integration ID',
      type: 'string',
      options: [],
      async: false,
      actionable: true,
    },
    { value: 'po_number', label: 'PO Number', type: 'string', options: [], async: false, actionable: true },
    { value: 'bol_number', label: 'BOL Number', type: 'string', options: [], async: false, actionable: true },
    { value: 'pro_number', label: 'PRO Number', type: 'string', options: [], async: false, actionable: true },
  ]
}
