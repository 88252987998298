import React, { useEffect, useState, FunctionComponent } from 'react'
import { useFetch, notify } from '../../../../components/component-items/helpers'

// Components
import CardLoading from '../../../../components/component-items/loading-popover'
import GlobalStyles from '../../../../components/component-items/styles'
import { Form } from 'react-bootstrap'
import { StatusBoxStyled } from '../../../../components/component-items/status-box'
// @ts-ignore
import { format } from 'sql-formatter'
import Pager, { Pages } from '../../../../components/component-items/pager'
import GenericModal from '../../../../components/component-items/modal'
import { AsnListFiltered } from '../../../asns/asn/asn-list'
import { OrderListFiltered } from '../../../warehouse/batches/batch/order-list'
import { ProductVariantListFiltered } from '../../../../pages/products/product/product-variant-list'
import { TransactionListFiltered } from '../../../billing/header/transaction-list-filtered'
import { AutomationPreviewProps } from './types/'

export const AutomationPreview: FunctionComponent<AutomationPreviewProps> = ({
  open,
  setOpen,
  category,
  condition,
  event,
  action,
}) => {
  const [page, setPage] = useState(1)
  const tableRowOptions = [20, 100, 250, 500]
  const [rows, setRows] = useState(tableRowOptions[0])
  const offset = (page - 1) * rows

  const { response, loaded, error } = useFetch(
    open ? `/api/core/automation/preview/?limit=${rows}&offset=${offset}` : '',
    {
      method: 'POST',
      body: JSON.stringify({ ...{ category, condition, event, action }, preview: true }),
    }
  )

  useEffect(() => {
    if ((loaded && error) || response?.error) {
      notify({ type: 'error', message: error || response?.error?.toString() })
    }
  }, [response])

  const orders = loaded ? response!['orders']?.['results'] : []
  const asns = loaded ? response!['asns']?.['results'] : []
  const returns = loaded ? response!['returns']?.['results'] : []
  const productVariants = loaded ? response!['product_variants']?.['results'] : []
  const billingTransactions = loaded ? response!['billings']?.['results'] : []

  const query = loaded ? response!['query'] : ''

  const filtered = loaded ? response!['filtered'] : 0
  const total = loaded ? response!['total'] : 0
  const totalPages = loaded && total ? Math.ceil(total / rows) : 1

  let formattedQuery = ''
  try {
    formattedQuery = query ? format(query, { language: 'postgresql' }) : ''
  } catch (error) {
    console.error('SQL formatting error:', error)
  }

  return (
    <GenericModal
      heading={
        <span>
          Automation Preview{' '}
          <small>
            {filtered} out of {total} Total
          </small>
        </span>
      }
      show={open}
      size="xl"
      onHide={() => setOpen(false)}
      buttons={<GlobalStyles.Button onClick={() => setOpen(false)}>Close</GlobalStyles.Button>}
    >
      <div style={{ minHeight: 500, padding: '0 1em 2em' }}>
        <Form.Label style={{ margin: '1em' }}>Results (Limit {rows})</Form.Label>
        {orders?.length > 0 ? (
          <OrderListFiltered orders={orders} />
        ) : asns?.length > 0 ? (
          <AsnListFiltered asns={asns} />
        ) : returns?.length > 0 ? (
          <AsnListFiltered asns={returns} />
        ) : productVariants?.length > 0 ? (
          <ProductVariantListFiltered productVariants={productVariants} />
        ) : billingTransactions?.length > 0 ? (
          <TransactionListFiltered transactions={billingTransactions} />
        ) : null}
        <GlobalStyles.CardFooter>
          <Pages page={page} setPage={setPage} total={totalPages} show={5} />
          <Pager options={tableRowOptions} page={page} total={total} rows={rows} setRows={setRows} />
        </GlobalStyles.CardFooter>
        {query ? (
          <>
            <Form.Label style={{ margin: '1em' }}>SQL Query</Form.Label>
            <StatusBoxStyled
              className="silver"
              style={{ margin: '0 0.9em', whiteSpace: 'pre-line', userSelect: 'all' }}
            >
              <pre>{formattedQuery}</pre>
            </StatusBoxStyled>
          </>
        ) : null}
      </div>
      {!loaded ? <CardLoading error={error} /> : null}
    </GenericModal>
  )
}
