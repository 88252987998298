import React, { useState, useEffect, FunctionComponent, useRef } from 'react'

// Components
import GlobalStyles, { MultiSelect } from '../../../../components/component-items/styles'
import { TableCell } from '../../../../components/component-items/datatable'
import { Form, InputGroup } from 'react-bootstrap'
import { StatusBoxStyled } from '../../../../components/component-items/status-box'
import AsyncSelect from 'react-select/async'
import Select from 'react-select'
import { toast } from 'react-toastify'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faLayerPlus, faCalendarWeek } from '@fortawesome/pro-duotone-svg-icons'

// Types
import {
  FiltersProps,
  defaultFilters,
  SendNotificationType,
  userList,
  productVariantListOptions,
  OrderFieldTypes,
  AddressFieldTypes,
  ASNFieldTypes,
  ReturnFieldTypes,
  InventoryFieldTypes,
  ProductVariantFieldTypes,
  BillingFieldTypes,
  OrderActionOptions,
  ASNActionOptions,
  ReturnActionOptions,
  InventoryActionOptions,
  ProductVariantActionOptions,
  BillingActionOptions,
} from './types'
import { Calendar } from 'react-date-range'

import styled from 'styled-components'
import { useClick } from '../../../../components/component-items/helpers'
import MentionComponent from '../../../../components/component-items/mentions'

export const DateRangeStyled = styled.div`
  position: absolute;
  bottom: 32px;
  right: -18px;
  z-index: 10;
  overflow: scroll;
  background-color: white;
  box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.19);
  margin: 2em;
  width: fit-content;
`

type ActionsProps = {
  automationType: string
  filters: FiltersProps
  event: any
  actions: any
  setActions: (actions: any) => void
  children?: React.ReactNode
  r: number
  g: number
  b: number
}
export const Actions: FunctionComponent<ActionsProps> = ({
  automationType,
  filters,
  event,
  actions,
  setActions,
  children,
  r,
  g,
  b,
}) => {
  const [showCalendar, setShowCalendar] = useState(false)
  const ref = useRef<HTMLLIElement>(null)
  useClick(ref, setShowCalendar)

  const mentionRef = useRef<any>(null)

  const [logical, setLogical] = useState(actions.logical)
  useEffect(() => {
    setActions({ ...actions, logical: logical })
  }, [logical])

  function addRow() {
    setLogical((logical: FiltersProps[]) => [...logical, defaultFilters])
  }

  const setType = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === 'logical_not') {
      setLogical([defaultFilters])
    } else {
      setLogical([defaultFilters, defaultFilters])
    }
    setActions({ ...defaultFilters, type: e.target.value })
  }

  const isLogical = ['logical_and', 'logical_or', 'logical_not'].includes(actions.type)
  const isEditItem = ['edit_order_item', 'edit_asn_item', 'edit_return_item'].includes(actions.type)

  // Instantiate Field Types
  const orderFieldList = OrderFieldTypes()
  const addressFieldList = AddressFieldTypes()
  const asnFieldList = ASNFieldTypes()
  const returnFieldList = ReturnFieldTypes()
  const inventoryFieldList = InventoryFieldTypes()
  const productVariantFieldList = ProductVariantFieldTypes()
  const billingFieldList = BillingFieldTypes()

  let fieldList: any = []
  if (automationType === 'order') {
    if (actions.type === 'edit_order_field') {
      fieldList = [...orderFieldList]
    } else if (actions.type === 'edit_address_field') {
      fieldList = [...addressFieldList]
    }
  } else if (automationType === 'asn') {
    if (actions.type === 'edit_asn_field') {
      fieldList = [...asnFieldList]
    }
  } else if (automationType === 'return') {
    if (actions.type === 'edit_return_field') {
      fieldList = [...returnFieldList]
    }
  } else if (automationType === 'inventory') {
    if (actions.type === 'edit_inventory_field') {
      fieldList = [...inventoryFieldList]
    }
  } else if (automationType === 'product_variant') {
    if (actions.type === 'edit_product_variant_field') {
      fieldList = [...productVariantFieldList]
    }
  } else if (automationType === 'billing') {
    if (actions.type === 'edit_billing_field') {
      fieldList = [...billingFieldList]
    }
  }

  const fieldOperator: any = fieldList?.find((t: any) => t.value === actions.result.field_type)
  const fieldOptions: any = fieldOperator?.options
  const fieldType: any = fieldOperator?.type

  const [asyncFieldOptions, setAsyncFieldOptions]: any = useState([])
  useEffect(() => {
    // Load async options and set them when they are ready
    const loadOptionsAsync = async () => {
      const options = await fieldOptions() // Assuming fieldOptions is a function that fetches options
      setAsyncFieldOptions(options)
    }

    if (fieldOperator?.async) {
      loadOptionsAsync()
    }
  }, [])

  useEffect(() => {
    let field_value: any = ''
    if (actions.type === 'send_notification') {
      field_value = mentionRef
    } else if (fieldType === 'date') {
      field_value = new Date()
    } else if (fieldType === 'boolean') {
      field_value = false
    }
    setActions({
      ...actions,
      result: { ...actions.result, field_value: field_value },
    })
  }, [actions.result.field_type])

  return (
    <>
      <GlobalStyles.DataTable>
        <tbody>
          <tr style={{ border: 'unset' }}>
            <TableCell style={{ background: 'rgb(0,0,0,0)' }}>
              <Form.Select required value={actions.type} onChange={(e) => setType(e)}>
                <option value={''} hidden>
                  - Field -
                </option>
                {automationType === 'order' ? (
                  <OrderActionOptions {...{ event }} />
                ) : automationType === 'asn' ? (
                  <ASNActionOptions {...{ event }} />
                ) : automationType === 'return' ? (
                  <ReturnActionOptions {...{ event }} />
                ) : automationType === 'inventory' ? (
                  <InventoryActionOptions {...{ event }} />
                ) : automationType === 'product_variant' ? (
                  <ProductVariantActionOptions {...{ event }} />
                ) : automationType === 'billing' ? (
                  <BillingActionOptions {...{ event }} />
                ) : null}
              </Form.Select>
              {isLogical ? (
                <GlobalStyles.DataTable>
                  <tbody>
                    <tr style={{ border: 'unset' }}>
                      <TableCell style={{ background: `rgb(${r}, ${g}, ${b})`, border: '1px solid #e1eaf1' }}>
                        {logical.map((newFilters: FiltersProps, index: number) => (
                          <React.Fragment key={index}>
                            <Actions
                              automationType={automationType}
                              filters={filters}
                              event={event}
                              actions={newFilters}
                              setActions={(e: any) =>
                                setLogical((logical: FiltersProps[]) => [
                                  ...logical.slice(0, index),
                                  { ...e },
                                  ...logical.slice(index + 1),
                                ])
                              }
                              r={r * 0.95}
                              g={g * 0.95}
                              b={b * 0.95}
                            >
                              {actions.type !== 'logical_not' ? (
                                <TableCell
                                  center
                                  style={{ verticalAlign: 'unset', width: 50, background: 'rgb(0,0,0,0)' }}
                                  input={
                                    <div className="dropdown__container" style={{ minWidth: 60 }}>
                                      {logical.length > 2 ? (
                                        <button
                                          type="button"
                                          onClick={() =>
                                            setLogical((logical: FiltersProps[]) =>
                                              logical.filter((c) => c !== newFilters)
                                            )
                                          }
                                        >
                                          <FontAwesomeIcon icon={faTrashAlt} />
                                        </button>
                                      ) : null}
                                      <button type="button" onClick={() => addRow()}>
                                        <FontAwesomeIcon icon={faLayerPlus} />
                                      </button>
                                    </div>
                                  }
                                />
                              ) : null}
                            </Actions>
                            {index + 1 !== logical.length ? (
                              <StatusBoxStyled
                                className={'blue'}
                                style={{ margin: '1em auto', width: 100, textAlign: 'center' }}
                              >
                                {actions.type === 'logical_and' ? 'AND' : 'OR'}
                              </StatusBoxStyled>
                            ) : null}
                          </React.Fragment>
                        ))}
                      </TableCell>
                    </tr>
                  </tbody>
                </GlobalStyles.DataTable>
              ) : null}
            </TableCell>
            {fieldList?.length > 0 ? (
              <>
                <TableCell style={{ background: 'rgb(0,0,0,0)' }}>
                  <Form.Select
                    required
                    value={actions.result.field_type}
                    onChange={(e) =>
                      setActions({ ...actions, result: { ...actions.result, field_type: e.target.value } })
                    }
                  >
                    <option value="" disabled hidden>
                      -- Field Type --
                    </option>
                    {fieldList
                      ?.filter((a: any) => a.actionable)
                      .map((type: any) => (
                        <option key={type.value} value={type.value}>
                          {type.label}
                        </option>
                      ))}
                  </Form.Select>
                </TableCell>
                {fieldOperator?.async ? (
                  <TableCell style={{ background: 'rgb(0,0,0,0)' }}>
                    <MultiSelect>
                      <AsyncSelect
                        cacheOptions
                        defaultOptions={true}
                        loadOptions={fieldOptions}
                        value={asyncFieldOptions.find(({ value }: any) => value === filters.result.field_value)}
                        onChange={(e: any) =>
                          setActions({
                            ...actions,
                            result: { ...actions.result, field_value: e.value },
                          })
                        }
                        closeMenuOnSelect={false}
                        className="basic-multi-select"
                      />
                    </MultiSelect>
                  </TableCell>
                ) : fieldOptions?.length > 0 ? (
                  <TableCell style={{ background: 'rgb(0,0,0,0)' }}>
                    <Select
                      options={fieldOptions}
                      value={fieldOptions.find(({ value }: any) => value === filters.result.field_value)}
                      onChange={(e: any) =>
                        setActions({
                          ...actions,
                          result: { ...actions.result, field_value: e.value },
                        })
                      }
                    ></Select>
                  </TableCell>
                ) : fieldType === 'boolean' ? (
                  <TableCell style={{ background: 'rgb(0,0,0,0)' }}>
                    <Select
                      options={[
                        { label: 'True', value: true },
                        { label: 'False', value: false },
                      ]}
                      value={
                        actions.result.field_value ? { label: 'True', value: true } : { label: 'False', value: false }
                      }
                      onChange={(e: any) =>
                        setActions({
                          ...actions,
                          result: { ...actions.result, field_value: e.value },
                        })
                      }
                    />
                  </TableCell>
                ) : fieldType === 'date' ? (
                  <TableCell style={{ background: 'rgb(0,0,0,0)', position: 'relative' }} ref={ref}>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        // isInvalid={recurrenceEnd === 'stop-after-date' && !endDate}
                        value={new Date(actions.result.field_value)?.toLocaleDateString('en-US').replace(/\//g, '-')}
                        placeholder="Choose A Date"
                        onFocus={() => setShowCalendar(true)}
                      />
                      <InputGroup.Text id="inputGroupAppend">
                        <FontAwesomeIcon icon={faCalendarWeek} />
                      </InputGroup.Text>
                    </InputGroup>
                    {showCalendar && (
                      <DateRangeStyled>
                        <Calendar
                          onChange={(e: any) => {
                            setActions({
                              ...actions,
                              result: { ...actions.result, field_value: e },
                            })
                            setShowCalendar(false)
                          }}
                          date={actions.result.field_value ? new Date(actions.result.field_value) : new Date()}
                          className="hide-in-percy"
                        />
                      </DateRangeStyled>
                    )}
                  </TableCell>
                ) : (
                  <TableCell style={{ background: 'rgb(0,0,0,0)' }}>
                    <Form.Control
                      type="text"
                      required
                      placeholder=""
                      value={actions.result.field_value}
                      onChange={(e) =>
                        setActions({
                          ...actions,
                          result: { ...actions.result, field_value: e.target.value },
                        })
                      }
                    />
                  </TableCell>
                )}
              </>
            ) : isEditItem ? (
              <>
                <TableCell style={{ padding: '5px 5px' }}>
                  <MultiSelect>
                    <AsyncSelect
                      cacheOptions
                      defaultOptions={true}
                      placeholder="SKU"
                      loadOptions={productVariantListOptions}
                      value={actions.result.field_type}
                      onChange={(e) => setActions({ ...actions, result: { ...actions.result, field_type: e } })}
                      closeMenuOnSelect={false}
                      className="basic-multi-select"
                    />
                  </MultiSelect>
                </TableCell>
                <TableCell style={{ background: 'rgb(0,0,0,0)' }}>
                  <Form.Control
                    required
                    type="number"
                    placeholder="quantity"
                    value={actions.result.field_value}
                    onChange={(e) =>
                      setActions({ ...actions, result: { ...actions.result, field_value: e.target.value } })
                    }
                  />
                </TableCell>
              </>
            ) : actions.type === 'send_notification' ? (
              <>
                <TableCell style={{ background: 'rgb(0,0,0,0)' }}>
                  <Form.Select
                    required
                    value={actions.result.field_type}
                    onChange={(e) =>
                      setActions({ ...actions, result: { ...actions.result, field_type: e.target.value } })
                    }
                  >
                    <SendNotificationType automationType={automationType} />
                  </Form.Select>
                </TableCell>
                {actions.result.field_type === 'email' ? (
                  <TableCell style={{ background: 'rgb(0,0,0,0)' }}>
                    <Form.Control
                      required
                      type="text"
                      placeholder="example@email.co, "
                      value={actions.result.notification_to}
                      onChange={(e) =>
                        setActions({ ...actions, result: { ...actions.result, notification_to: e.target.value } })
                      }
                    />
                  </TableCell>
                ) : actions.result.field_type === 'sms' ? (
                  <TableCell style={{ background: 'rgb(0,0,0,0)' }}>
                    <Form.Control
                      required
                      type="text"
                      placeholder="1234567890, "
                      value={actions.result.notification_to}
                      onChange={(e) =>
                        setActions({ ...actions, result: { ...actions.result, notification_to: e.target.value } })
                      }
                    />
                  </TableCell>
                ) : actions.result.field_type === 'slack' ? (
                  <TableCell style={{ background: 'rgb(0,0,0,0)' }}>
                    <Form.Control
                      required
                      type="text"
                      placeholder="slack-channel, "
                      value={actions.result.notification_to}
                      onChange={(e) =>
                        setActions({ ...actions, result: { ...actions.result, notification_to: e.target.value } })
                      }
                    />
                  </TableCell>
                ) : actions.result.field_type === 'app' ? (
                  <TableCell style={{ background: 'rgb(0,0,0,0)' }}>
                    <MultiSelect style={{ minWidth: 200 }}>
                      <AsyncSelect
                        cacheOptions
                        defaultOptions={true}
                        placeholder="Users"
                        loadOptions={userList}
                        value={actions.result.notification_to}
                        onChange={(e) => setActions({ ...actions, result: { ...actions.result, notification_to: e } })}
                        closeMenuOnSelect={false}
                        className="basic-multi-select"
                        isMulti={true}
                      />
                    </MultiSelect>
                  </TableCell>
                ) : null}
                {actions.type !== 'send_notification' && (
                  <TableCell style={{ background: 'rgb(0,0,0,0)' }}>
                    <Form.Control
                      required
                      type="text"
                      placeholder=""
                      value={actions.result.field_value}
                      onChange={(e) =>
                        setActions({ ...actions, result: { ...actions.result, field_value: e.target.value } })
                      }
                    />
                  </TableCell>
                )}
              </>
            ) : null}
            {children}
          </tr>
          {actions.type === 'send_notification' && (
            <tr>
              <TableCell style={{ background: 'rgb(0,0,0,0)' }} colspan={5}>
                <MentionComponent ref={mentionRef} />
              </TableCell>
            </tr>
          )}
        </tbody>
      </GlobalStyles.DataTable>
    </>
  )
}
