import React from 'react'
import { globalState } from '../../../../../store'

export const BillingConditionOptions = () => (
  <>
    <option value={'all_billing_transactions'}>All Billing Transactions</option>
    <optgroup label="Billing Options">
      <option value={'billing_transaction_field'}>Billing Transaction Field</option>
    </optgroup>
    <optgroup label="Logical Type">
      <option value={'logical_and'}>AND</option>
      <option value={'logical_or'}>OR</option>
      <option value={'logical_not'}>NOT</option>
    </optgroup>
  </>
)

export const BillingEventOptions = () => (
  <>
    <option value={'billing_created'}>Billing Transaction Created</option>
  </>
)

export const BillingActionOptions = ({ event }: any) => {
  return (
    <>
      <optgroup label="Notification">
        <option value={'send_notification'}>Send Notification</option>
      </optgroup>
      <optgroup label="Logical Type">
        <option value={'logical_and'}>AND</option>
      </optgroup>
    </>
  )
}

export const BillingCategoryTypes = [
  { value: 'N', label: 'Credit', actionable: false, color: 'green' },
  { value: 'C', label: 'Debit', actionable: false, color: 'red' },
  { value: 'R', label: 'Processing', actionable: false, color: 'blue' },
  { value: 'K', label: 'Picking', actionable: false, color: 'blue' },
  { value: 'P', label: 'Postage', actionable: false, color: 'blue' },
  { value: 'Z', label: 'Packaging', actionable: false, color: 'blue' },
  { value: 'O', label: 'Rush Order Fee', actionable: false, color: 'blue' },
  { value: 'X', label: 'Order Cancellation', actionable: false, color: 'blue' },
  { value: 'B', label: 'Order Manual Billing', actionable: false, color: 'blue' },
  { value: 'G', label: 'Casepack Pallet Fee', actionable: false, color: 'blue' },
  { value: 'H', label: 'Casepack Master Fee', actionable: false, color: 'blue' },
  { value: 'I', label: 'Casepack Inner Fee', actionable: false, color: 'blue' },
  { value: 'S', label: 'Storage', actionable: false, color: 'blue' },
  { value: 'M', label: 'Monthly Minimum', actionable: false, color: 'blue' },
  { value: 'J', label: 'Project Fee', actionable: false, color: 'blue' },
  { value: 'A', label: 'Non-compliant Receiving', actionable: false, color: 'blue' },
  { value: 'E', label: 'Return Processing', actionable: false, color: 'blue' },
  { value: 'F', label: 'Return Picking', actionable: false, color: 'blue' },
  { value: 'D', label: 'Document Printing', actionable: false, color: 'blue' },
  { value: 'L', label: 'Label Printing', actionable: false, color: 'blue' },
  { value: 'Q', label: 'Lot Control', actionable: false, color: 'blue' },
  { value: 'T', label: 'Payment', actionable: false, color: 'blue' },
  { value: 'payment_fees', label: 'Payment Fees', actionable: false, color: 'blue' },
]

export const BillingFieldTypes = () => {
  const {
    state: { activeWarehouses: activeWarehousesWithID },
  } = globalState()

  const activeWarehouses = activeWarehousesWithID.map((option: any) => ({ label: option.name, value: option.id }))
  return [
    { value: 'id', label: 'Billing ID', type: 'number', options: [], async: false, actionable: false },
    { value: 'amount', label: 'Amount', type: 'number', options: [], async: false, actionable: false },
    { value: 'description', label: 'Description', type: 'string', options: [], async: false, actionable: false },
    { value: 'order', label: 'Order', type: 'string', options: [], async: false, actionable: false },
    { value: 'shipnotice', label: 'Ship Notice', type: 'string', options: [], async: false, actionable: false },
    { value: 'return_notice', label: 'Return Notice', type: 'string', options: [], async: false, actionable: false },
    { value: 'project', label: 'Project', type: 'string', options: [], async: false, actionable: false },

    {
      value: 'warehouse',
      label: 'Warehouse',
      type: 'number',
      options: activeWarehouses,
      async: false,
      actionable: true,
    },
    {
      value: 'category',
      label: 'Category',
      type: 'string',
      options: BillingCategoryTypes,
      async: false,
      actionable: false,
    },
    { value: 'created', label: 'Created', type: 'date', options: [], async: false, actionable: false },
    { value: 'updated', label: 'Updated', type: 'date', options: [], async: false, actionable: false },
  ]
}
